import React from 'react'

import Layout from '../../../components/layout'
import SEO from "../../../components/seo"
import TwoColumn from "../../../components/two-column"
import Popover from '../../../components/popover'

const bannerContent = {
  title: "All the statistics you need to run your business in one place.",
  description: [
    {
      text: "Your Billsby Dashboard collates all of the facts, figures and information you need to run your subscription business in one place - so you can always keep on top of what's going on and how it'll impact your company."
    }
  ],
  buttons: [
    {
      isExternal: true,
      url: 'https://app.billsby.com/registration',
      buttonColor: 'orange',
      buttonText: 'Sign up today'
    },
    {
      scheduleButton: true
    }
  ],
  sectionPageBanner: true,
  image: require('../../../images/dashboard-header.svg'),
  imageName: 'dashboard-header svg',
  backgroundColor: '#f3f5fb',
  textColor: '#252422',
  imagePosition: 'right',
  svg: true
}

const shareData = {
  title: "Share data and insights with everyone on your team.",
  description: [
    {
      text: "Every Billsby account includes unlimited teammates - which means everyone that needs access to the Billsby Dashboard and our reporting features can have it at no extra cost to your company. "
    },
    {
      text: " No more manually collating reports and statistics - let everyone self-serve and get the data, insights and reporting they need instantly."
    }
  ],
  image: 'share-data.png',
  backgroundImage: require('../../../images/share-data.png'),
  imageName: 'share data',
  backgroundColor: '#243F5C',
  textColor: '#FFFFFF',
  imagePosition: 'full-right'
}

const imageWithPopover = {
  title: 'We\'ve made keeping track of the numbers easy',
  description: 'The Billsby dashboard has all of the data points and information you need to run your subscription business day-to-day, with lots of data and a simple and easy to interpret design.',
  imageName: 'plan creation',
  image: require('../../../images/dashboard-in-life-customer.png'),
  // srcSet:'plan-creation@2x.png 2x',
  popover: [
    {
      popoverContent: {
        title: 'MRR',
        text: 'Understand how much recurring revenue your business is generating each month'
      }
    },
    {
      popoverContent: {
        title: 'Subscriptions',
        text: 'See how many subscriptions you have at one glance'
      }
    },
    {
      popoverContent: {
        title: 'Net revenue MTD',
        text: 'See how much you\'ve invoiced for so far this month'
      }
    },
    {
      popoverContent: {
        title: 'Net payments MTD',
        text: 'See how much you\'ve collected from your customers'
      }
    },
    {
      popoverContent: {
        title: 'Unpaid invoices',
        text: 'Discover the amount of bad debt on your books'
      }
    },
    {
      popoverContent: {
        title: 'Expired cards',
        text: 'Reach out to customers whose payments are going to fail, before they do'
      }
    },
    {
      popoverContent: {
        title: 'Revenue this month',
        text: 'Understand how your revenue compares to the previous month, to see how your business is growing'
      }
    },
    {
      popoverContent: {
        title: 'Revenue today',
        text: 'See how your day-on-day revenue is growing (useful if you spread billing cycles through the month)'
      }
    },
    {
      popoverContent: {
        title: 'Customer base',
        text: 'See how your customer base is growing, accounting for churn and lost customers too'
      }
    },
    {
      popoverContent: {
        title: 'Signups this month',
        text: 'See how new marketing is helping you to attract new customers to your business'
      }
    }
  ]
}

const dashboard = () => {
  return (
    <Layout className="reporting dashboard">
      <SEO 
        title="Dashboard reporting | Billsby" 
        description="Recurring billing and subscription management software with dashboard reporting and revenue statistics. Book a demo or Sign up today | Billsby" 
        url="https://www.billsby.com/product/reporting/dashboard"
      />

      <TwoColumn content={bannerContent}/>


      <div className="section-dashboard section-swiper-tabs">
        <div className="container"> 
          <div className="content-wrapper"> 
            <div className="text-wrapper">
              <h2 className="title"> {imageWithPopover.title} </h2>
              <p> {imageWithPopover.description} </p>
            </div>
            <div className="image-with-popover">
          <img src={imageWithPopover.image} alt="popover"/>
          <div className="popovers">
          { imageWithPopover.popover &&
            imageWithPopover.popover.map ((popoverContent, j) => {
              return (
                <>
                <Popover key={j} content={popoverContent.popoverContent} />
                </>
              )
            })
          }  
          </div>
        </div>
          </div>
        </div>
      </div>


      <TwoColumn content={shareData}/>
    </Layout>
  )
}

export default dashboard